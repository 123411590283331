@import '../../../styles/variables';

.tree-node-content {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.tree-node-title {
  width: 70%;
}

.tree-node-add {
  width: 200px;
}

.tree-node-delete {
  margin-left: 10px;
  width: 32px;
}

.ant-tree-treenode {
  width: 100%;
  padding-bottom: 10px;
  .ant-tree-node-content-wrapper {
    width: 100%;
  }
}