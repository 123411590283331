@import '../../../styles/variables';

.hmp-cover-image-editor {

  .hmp-cover-image-editor-dropzone-container {
    width: 300px;
    height: 200px;
    max-height: 200px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .hmp-cover-image-editor-dropzone {
      height: 95%;
      width: 95%;
      background-color: #fff;
      border: 2px dashed rgb(187, 186, 186);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      input {
        display: none;
      }

      .hmp-cover-image-editor-dropzone-active,
      .hmp-cover-image-editor-dropzone-inactive,
      .hmp-cover-image-editor-dropzone-accept,
      .hmp-cover-image-editor-dropzone-reject {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 16px;

        .anticon {
          font-size: 50px;
        }

        img {
          height: 90%;
          width: 90%;
          max-width: 300px;
          max-height: 200px;
        }

        .CircularProgressbar {
          width: 100px;
          height: 100px;
        }
      }
    }
  }

  .hmp-cover-image-editor-image-list {
    margin-top: 10px;
    margin-bottom: 10px;
    min-height: 75px;

    .hmp-cover-image-editor-choice {
      font-size: 16px;

      img {
        width: 75px;
        max-height: 75px;
      }
    }
  }
}