@import '../../../styles/variables';

.admin-management-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.admin-action-container {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: start;
  align-items: center;
}
.admin-action {
  margin-left: 10px;
  color: #449AFF;
  cursor: pointer;
}
.admin-table-row {
  cursor: pointer;
}

.table-selection-title {
  font-size: 14px;
}
.table-selection-actions {
  width: 100%;
  display: flex;
  margin-bottom: 8px;
}

.table-selection-action {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 8px;

  .anticon {
    margin-right: 4px;
  }
}