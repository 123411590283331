
#header {
  padding: 5px 10px 25px 10px;

  #header-left {
    float: left;
  }

  #header-right {
    float: right;
    margin-right: 15px;

    span {
      margin-left: 5px;
      margin-right: 5px;
    }

    .ant-badge {
      font-size: 20px;
      color: white;
    }

    .logout-btn {
      color: white;
    }
  }
}
