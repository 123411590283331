
.addPostModal {


  .upload {
    margin-left: 30px;
    margin-top: 10px;
  }

  .video {
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    .video-web-link {
      width: 97%;
    }
  }

  .postLinks {
    display: flex;
    flex-direction: row;
  }

  #studyArmCheckboxes {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
  }

  #formContainer {
    display: flex;
    flex-direction: column;

    #newPostBody: {
      height: 300px;
      border: 2px solid blue;
    }

    .formRow {
      margin: 10px;
      flex-direction: row;
    }

    .input {
       margin-top: 10px;
    }

    .optionInput {
       margin-top: 10px;
       margin-left: 30px;
       width: 300px;
    }

    .label {
      color: black;
    }

      .addOptionButton {
        margin-top: 10px;
        margin-left: 30px;
      }
  }
}

.options-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}