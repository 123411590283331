@import '../../styles/variables';

#ant-checkbox-item {
  display: block;
  margin-right: 0;
}

.ant-card-actions > li {
  color: #454545;
}

.conditional-item {
  transition: height 1s ease-out;
  height: 0;
  overflow: hidden;
}

#collapse-header .ant-collapse-header {
  padding: 0px 0px 0px 0px;
}

#edit {
  background: #fff;
}

.form-action-container {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  .form-action {
    margin-right: 10px;
  }
}