@import '../../styles/variables';

.activity-form {
  margin-top: 10px;

  .question-switch {
    margin-bottom: 10px;
  }

  .activity-image-upload {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    margin-top: 10px;

    .upload-list-inline .ant-upload-list-item {
      float: left;
      width: 200px;
      margin-right: 8px;
    }
    
    .upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
      float: right;
    }
    .upload-list-inline .ant-upload-animate-enter {
      animation-name: uploadAnimateInlineIn;
    }
    .upload-list-inline .ant-upload-animate-leave {
      animation-name: uploadAnimateInlineOut;
    }
  }

  .activity-gradient {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;

    .color-panel {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 10px;

      .color-option {
        width: 50px;
        height: 50px;
        margin: 5px;
        border: solid 0px white;
      }

      .color-option:hover {
        box-shadow: 0px 0px 1px 1px rgba(0,0,0, 0.4);
      }

      .selected {
        border: black solid 1px;
      }
    }
  }

  .dynamic-question-items {
    border-bottom: 1px solid #eeeef1;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;

    .question-list-container {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      border-bottom: 1px solid #eeeef1;

      .question-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        .question-item {
          padding: 10px;
          width: 100%;
          white-space: pre;
        }
      }
    }
  }

  

  .dynamic-delete-button {
    cursor: pointer;
    position: relative;
    top: 4px;
    font-size: 18px;
    color: #999;
    transition: all 0.3s;
    margin-left: 10px;
  }
  .dynamic-delete-button:hover {
    color: rgb(241, 120, 120);
  }
  .dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  .options-container {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    
    .option-props {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      .option-prop {
        margin-right: 10px;
      }
    }
  }

  .dynamic-option-items {
    border-bottom: 1px solid #eeeef1;
    width: 100%;

    .option-list-container {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      margin: 0;
      padding: 0;
      list-style-type: none;
      .option-container {
        width: 100%;
        .option-props {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: start;
          align-items: center;
          .option-prop {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.dynamic-feedback-items {
  width: 100%;

  .feedback-list-item-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;

    .feedback-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      width: 100%;
      padding-bottom: 10px;
      border-bottom: 1px solid #eeeef1;

      .feedback-item {
        padding: 10px;
        width: 100%;
        white-space: pre;
      }
    }
  }
}

.feedback-item {
  width: 100%;
}

.dashed-danger-button {
  width: 100%;
  margin-bottom: 10px;
  line-height: 1.499;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0,0,0,.015);
  cursor: pointer;
  transition: all .3s cubic-bezier(.645,.045,.355,1);
  height: 32px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 4px;
  color: rgba(0,0,0,.65);
  background-color: #fff;
  border-color: #ff4d4f;
  color: #ff4d4f;
}

.dashed-danger-button:hover {
  border-color: #ff8e8f;
  color: #ff8e8f;
}

.dashed-danger-button:focus {
  outline: none;
}

.edit-switch {
  margin-left: 10px;
}

.activity-form-title {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  .activity-title-text {
    min-width: 150px;
  }
}

.activity-popover-content {
  width: auto;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.activity-preview-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .activity-preview {
    width: 320px;
    height: 568px;
    border-radius: 38px;
    padding: 10px;
    background-color: #2f2f31;
    box-shadow: 0px 0px 1px 1px rgba(0,0,0, 0.4);

    .activity-preview-screen {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-color: white;
      border-radius: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      .activity-preview-image {
        width: inherit;
        height: inherit;
        display: inline-block;
        vertical-align: middle;
        font: 0/0 serif;
        text-shadow: none;
        color: transparent;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: inherit;
        padding-top: 66,64%;
        img {
          width: inherit;
          height: inherit;
          display: inline-block;
          vertical-align: middle;
          font: 0/0 serif;
          text-shadow: none;
          color: transparent;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          border-radius: inherit;
          padding-top: 66,64%;
        }
      }
    }
  }
}