$color-black-pearl: #1C2027;      // content
$color-black-rock:  #2E2F39;      // sidebar
$color-blue-zodiac: #484C5F;      // header, card header
$color-gun-powder:  #47495B;      // secondary
$color-heliotrope:  #924DFC;      //
$color-mulled-wine: #4F5165;      // card background
$color-ship-cove:   #8185B3;      // font primary


$font-color-primary: $color-ship-cove;
$font-color-secondary: #FFF;

$menu-background-color: $color-black-rock;
$menu-item-color: $font-color-secondary;
$menu-item-hover-color: $color-heliotrope;
$menu-selected-item-background-color: $color-blue-zodiac;
$menu-selected-item-color: $font-color-primary;

$submenu-background-color: $color-gun-powder;

$primary-color: #6DB6FF;
$error-message-color: red;

$p-color: #1C2027;   // popup
