@import '../../../styles/variables';

#form {
  width: 100%;
  min-height: 100vh;

  .ant-skeleton-paragraph li {
    background: $font-color-primary;
  }

  .ant-skeleton-title {
    background: $font-color-primary;
  }

  .fa-file-alt {
    transition: box-shadow 0.3s ease-in-out;
  }

  .fa-file-alt:hover {
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  }
}

.form-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}