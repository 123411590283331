@import '../../../styles/variables';

#dashboard {

 .row {
    margin-bottom: 15px;
  }

 .card {
   height: 250px;
   border-radius: 10px;
 }
}
