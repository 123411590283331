@import '../../styles/variables';

.hmp-qna-detail-card {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.qna-comment-list {
  background-color: #eeeef1;
}

.qna-post-comment {
  margin-top: 10px;
  padding-left: 10px;
}

.hmp-flag-icon-wrapper {
  font-size: 12px;
}

.red-flag {
  color: red;
  &:hover {
    color: rgb(255, 131, 131);
  }
}

.tag-bubble {
  margin-left: 10px;
  padding-left: 5px;
  padding-right: 5px;
  height: 14px;
  font-size: 12px;
  color: white;
  border-radius: 7px;
}
.tag-bubble.deleted {
  background-color: red;
}
.tag-bubble.hidden {
  background-color: gray;
}

.qna-form-title {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  .qna-title-text {
    min-width: 150px;
  }
}

.new-qna-post-form {

  TextArea {
    padding-top: 7px;
  }
}

.qna-input {
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  border: 0px solid;
  outline: solid #d9d9d9 1px;
  font-size: 14px;
  line-height: normal;
  height: 80px;
  width: 100%;
  background: none;
  resize: y;
  overflow-y: scroll;
  min-height: 50px;
  max-height: 200px;
}

.qna-post-question {
  white-space: pre-wrap;
}

.qna-post-answer {
  white-space: pre-wrap;
}

.hmp-qna-username {
  color: $font-color-primary;
}

#flagResolvePopup {

  #buttonRow {
    width: 300px;
    display: flex;
    justify-content: space-around;
    margin-bottom: 15px;
  }

  #titleRow {
    display: flex;
    padding-top: 10px;
    justify-content: center;
    border: 1px solid #8185B3;
    border-bottom: none;
    border-radius: 4px 4px 0px 0px;
  }

  #tableRow {
    width: 300px;
    display: flex;
    justify-content: space-around;
    border: 1px solid #8185B3;
    border-top: none;
    border-radius: 0 0 4px 4px;

    #table {
      width: 250px;

      .bottomRow {
       border-top: 1pt solid #8185B3
      }

      .center {
       text-align: center;
      }
    }
  }
}