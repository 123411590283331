
#participant-search {

 height: 100%;
 display: flex;
 flex-direction: column;

  #search-container {
    #arm-filter {
      margin-top: 10px;
    }
  }

  #search-results-container {
   height: 100%;
   flex-grow: 1;
  }

}

.table-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.table-filter-search {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  .hmp-search-input-container {
    width: 100%;
    margin-bottom: 10px;
  }
  .filter-items {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    .filter {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      margin-bottom: 10px;
      white-space: pre;
    }
  }
}