@import '../../styles/variables';

.thread-list {
  display: flex;
  flex-direction: column;
  height: 800px;

  .thread-list-header {
    display: flex;
    flex-direction: column;
    background-color: white;
    position: sticky;
    top: 0px;
    border-bottom: 1px solid #eeeef1;
    z-index: 10;
  }

  .thread-list-item {
    display: flex;
    height: 90px;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    padding: 10px;
    z-index: 1;
  }

  .thread-list-empty {
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: center;
  }

  .thread-list-loading {
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: center;
  }

  .thread-list-item-selected {
    background: #eeeef1;

    .one-line-fade:after {
      background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(238, 238, 241, 1) 50%);
    }
  }

  .thread-list-item:hover {
    background: #eeeef1;
    cursor: pointer;

    .one-line-fade:after {
      background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(238, 238, 241, 1) 50%);
    }
  }

  .thread-avatar-container {
    position: relative;
    z-index: 1;

    .thread-avatar {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 10px;
    }
  }

  .important {
    content: '';
    width: 15px;
    height: 15px;
    position: absolute;
    border-radius: 50%;
    top: 0px;
    left: 0px;
    color: orange;
    background-color: white;
  }

  .thread-item-content {
    width: 70%;
    height: 100%;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    z-index: 1;
    position: relative;

    .thread-from {
      font-size: 14px;
      margin: 0;
    }

    .thread-preview {
      font-size: 13px;
      width: 100%;
      color: #888;
      margin: 0;
    }

    .thread-title {
      font-size: 14px;
      font-weight: bold;
      text-transform: capitalize;
      margin: 0;
    }

    .unread {
      font-weight: bold;
      color: #000;
    }

    .thread-assignee-bubble {
      text-align: center;
      color: #fff;
      font-size: 12px;
      width: 35px;
      border-radius: 10px;
      margin-right: 1px;
      background-color: $font-color-primary;
    }

    .thread-assignee-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: relative;
    }
  }

  .thread-item-info {
    height: 100%;
    min-width: 24px;
    font-size: 12px;
    display: flex;
    text-align: end;
    flex-direction: column;
    align-self: flex-start;
    align-items: flex-end;
    z-index: 1;

    .thread-date {
      font-weight: bold;
      margin: 0;
      white-space: nowrap;
    }

    .thread-item-options {
      font-size: 12px;
      margin: 10px 10px 0 0;
    }

    .thread-item-option-hitbox {
      text-align: center;
      width: 10px;
    }
  }
}

.thread-content-no-selected {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.thread-content-no-selected span {
  font-size: 14px;
  color: #888;
  margin: 0;
}

.thread-filter-container {
  display: flex;
  flex-direction: row-reverse;
  margin: 5px 20px 10px;
  font-size: 12.5px;

  .thread-filter {
    text-align: right;
    margin-left: 20px;
  }
}

.scrollable {
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.thread-toolbar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  background-color: white;
  font-weight: 500;
  min-height: 25px;

  .toolbar-title {
    margin: 0px;
    font-size: 16px;
    font-weight: 600;
  }

  .left-items .right-items {
    flex: 1;
    padding: 10px;
    display: flex;
  }

  .right-items {
    flex-direction: row-reverse;
  }

  .left-items .toolbar-button {
    margin-right: 20px;
  }

  .right-items .toolbar-button {
    margin-left: 20px;
  }

  .toolbar-button {
    white-space: pre;
  }

  .toolbar-button-icon {
    transition: all 0.1s;
  }

  .spinner {
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}


.compose-message {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: white;
  border-top: 1px solid #eeeef1;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0px;
  height: 70px;
}

.compose-message-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.compose-message-form {
  width: 100%;
}

.compose-message-input {
  flex: 1;
  border: none;
  outline: none;
  font-size: 14px;
  height: 40px;
  width: 100%;
  background: none;
  resize: none;
  overflow-y: scroll;
  height: 70px;
}
.compose-message-input::-webkit-scrollbar {
  display: none;
}


.compose-message-input::placeholder {
  opacity: 0.3;
}

.compose-message .toolbar-button {
  color: #bbbbbf;
  margin-left: 15px;
}

.compose-message .toolbar-button:hover {
  color: #99999c;
}

.message-column {
  height: 100%;
  overflow: hidden;
  .thread-toolbar {
    border-bottom: 1px solid #eeeef1;
    position: sticky;
    top: 0px;
  }
}

.hmp-message-column-toolbar {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: space-around;
  .thread-toolbar {
    width: 100%;
    justify-content: space-between;
  }
}

.message-list {
  display: flex;
  flex-direction: column;
  height: 695px;
  width: 100%;
  overflow-y: scroll;

  .message-list-container {
    padding: 10px;
    height: 100%;
  }
}

.message {
  display: flex;
  flex-direction: column;
}

.message .timestamp {
  display: flex;
  justify-content: center;
  color: #999;
  font-weight: 600;
  font-size: 12px;
  margin: 10px 0px;
  text-transform: uppercase;
}

.message .bubble-container {
  font-size: 14px;
  display: flex;
}

.message .message-timestamp {
  height: 18px;
  font-size: 12px;
  display: flex;
  overflow: hidden;
  transition: all 2s linear;
}

/* Message Timestamp Animation */
.message .message-timestamp-transition-enter {
  height: 0px;
}

.message .message-timestamp-transition-enter-active {
  height: 18px;
  transition: height .3s linear;
}

.message .message-timestamp-transition-appear {
  height: 0px;
}

.message .message-timestamp-transition-appear-active {
  height: 18px;
  transition: height .3s linear;
}

.message .message-timestamp-transition-exit {
  height: 18px;
}

.message .message-timestamp-transition-exit-active {
  height: 0;
  transition: height .3s linear;
}

.message .message-timestamp-transition-exit-done {
  height: 0;
}

.message.mine .message-timestamp {
  justify-content: flex-end;
}

.message.mine .bubble-container {
  justify-content: flex-end;
}

.message.start .bubble-container .bubble {
  /* margin-top: 10px; */
  border-top-left-radius: 20px;
}

.message.end .bubble-container .bubble {
  border-bottom-left-radius: 20px;
  /* margin-bottom: 10px; */
}

.message.mine.start .bubble-container .bubble {
  border-top-right-radius: 20px;
}

.message.mine.end .bubble-container .bubble {
  border-bottom-right-radius: 20px;
}

.message .bubble-container .bubble {
  margin: 1px 0px;
  background: #f4f4f8;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 75%;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  white-space: pre-line;
}

.message.mine .bubble-container .bubble {
  background: $font-color-primary;
  color: white;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  white-space: pre-line;
}

.typing-indicator {
  height: 5px;
  font-style: italic;

  @keyframes blink {50% { color: transparent }}
  .loader__dot { animation: 1s blink infinite }
  .loader__dot:nth-child(2) { animation-delay: 250ms }
  .loader__dot:nth-child(3) { animation-delay: 500ms }
}

.one-line-fade {
  position: relative;
  height: 1.4em; /* exactly one line */
}
.one-line-fade:after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0px;
  width: 20%;
  height: 1.4em;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
}

