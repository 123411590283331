@import '../../styles/variables';

.hmp-progress-stat-card {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-width: 200px;
  width: 33%;

  .header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-weight: bold;
    font-size: large;

    .title {
      flex: 1;
    }

    .count {
      flex: 1;
      font-size: larger;
      text-align: right;
      max-width: 50px;
    }
  }

  .sub-title {
    font-size: medium;
  }

  .progress-bar {
    .ant-progress-text {
      color: $font-color-primary;
    }
  }
}