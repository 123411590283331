@import '../../../styles/variables';

.answerContainer {
}

.qna-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.qna-action-container {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: start;
  align-items: center;
  .qna-action {
    margin-right: 10px;
  }
}

.hmp-qna-topic-container {
  width: 100%;
  border-bottom: 1px solid #eeeef1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #B3B3B5;
}

.hmp-qna-date {
  color: $font-color-primary;
}

.hmp-qna-page-tab {
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  .hmp-qna-col {
    height: 100%;
    margin: 10px;
    border-bottom: 1px solid #eeeef1;

    &-empty {
      border-radius: 5px;
      box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
      overflow-y: auto;
      height: 65vh;
      background-color: #eeeef1;
    }

    &-post {
      overflow-y: auto;
      height: 65vh;
      margin: 10px;
    }

    #infinite-scroll-container {
      height: 75vh;
      overflow: scroll;
    }
  }

  .hmp-qna-loading-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 10px 0 0;
  }

  .hmp-qna-list-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    background: white;
    padding: 10px;
    margin-bottom: 10px;
    position: sticky;
    top: 0px;
    z-index: 2;
    border-bottom: 1px solid #eeeef1;

    .hmp-qna-list-toolbar {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: space-around;
      margin-bottom: 10px;


      .thread-toolbar {
        width: 100%;
        justify-content: space-between;
      }
    }

    .hmp-search-input-container {
      width: 100%;
    }

    .hmp-qna-list-title {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }

    .filter-items {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      margin-top: 10px;
      .filter {
        margin-right: 10px;
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: start;
        white-space: pre;
      }
    }
  }

  .dropdown-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-top: 10px;

    .dropdown {
      margin-right: 10px;
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      white-space: pre;

      .dropdown-box {
        min-width: 180px;
        width: auto;
      }
    }
  }

  .qna-action {
    color: $font-color-primary;
  }

  .hmp-qna-icon {
    color: $font-color-primary;
  }

  .post-tag-bubble {
    margin-right: 10px;
    padding-left: 5px;
    padding-right: 5px;
    height: 16px;
    font-size: 12px;
    color: white;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .post-tag-bubble.deleted {
    background-color: red;
  }
  .post-tag-bubble.hidden {
    background-color: gray;
  }

}