.inbox {
  width: 100%;
  height: 800px;
  background: #eeeef1;
  border-radius: 1px;
}

.scrollable {
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.ant-layout-sider-children .sidebar {
  background: white;
  min-width: 400px;
  height: 800px;
  border-radius: 1px;
  border-right: 1px solid #e3e3e3;
}

.content {
  background: white;
  border-radius: 1px;
  min-width: 400px;
}

.hmp-inbox-loading-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 10px 0 0;
}
