@import '../../styles/variables';

.ant-row.badge-grid-row {
  height: 600px;
  width: 768px;
  overflow-y: auto;
  overflow-x: hidden;
}

.ant-card.badge-card {
  height: 150px;
  width: 125px;
  text-align: center;
  cursor: pointer;

  img {
    width: 50%;
    align-self: center;
  }

  div {
    width: 100%;
  }

  .ant-card-body  {
    width: 100%;
    height: 100%;
    border: 1px solid transparent;
    border-radius: 2px;
    padding: 5px;
  }

  .ant-card-body:hover {
    background-color: #eeeef1;
  }
}

.ant-card.badge-card.selected {
  .ant-card-body {
    border: 1px solid blue;
  }
}

.badges-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#form-container {
  display: flex;
  flex-direction: column;

  .form-row {
    margin: 10px;
    flex-direction: row;
  }

  .input {
    margin-top: 10px;
  }

  .label {
    color: black;
  }

  .image {
    margin-top: 10px;
  }

  .ant-select-auto-complete {
    width: 200px;
  }

  .ant-select-selector {
    width: 200px;
  }

}

.badge-action-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  .badge-action {
    margin-right: 10px;
  }
}
