@import '../../../../styles/variables';

#participant {

  .ant-steps-navigation {
    background-color: #FFB100;
  }

  // TODO: The profile class is for the ParticipantViewCard component, move those classes out of the page definition.
  .profile {
    display: flex;
    flex-direction: column;

    .profile-header {
      font-size: 24px;
      font-weight: bold;
    }

    .profile-container {
      display: flex;
      flex-direction: row;
      flex-flow: row wrap;

      .avatar {
        width: 100px;
      }

      .demo {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        min-width: 350px;
        max-width: calc(100% - 200px);
        width: 100%;

        .demo-section {
          display: flex;
          flex-direction: column;
          margin-right: 20px;
          margin-bottom: 20px;
          min-width: 275px;

          .ant-row.ant-form-item, .demo-row {
            margin: 3px;
            display: flex;
            flex-direction: row;
            align-items: center;

            .ant-form-item-label, .label {
              text-align: right;
              line-height: normal;
              min-width: 155px;

              label {
                color: $font-color-primary;
                text-align: right;

                &:after {
                  content: ":";
                  margin-right: 10px;
                }
              }
            }

            .ant-form-item-control-wrapper {
              width: calc(100% - 125px);
              //.ant-form-item-control {
              //  padding-left: 5px;
              //  background-color: yellow;
              //}
            }
          }
        }
      }

      .actions {
        display: flex;
        flex-direction: column;
        align-items: center; // centered horizontally in column
        padding-top: 10px;
        width: 100px;
        justify-content: flex-start; // anchored at the top of the column
      }
    }
  }

  #tab-bar {

    .ant-tabs-bar {
      margin: 0;
    }

    margin-top: 10px;
  }

}

#participant-notes-container {

  margin-top: 10px;
  margin-bottom: 10px;

  .note-profile {
    display: flex;
    flex-direction: row;

    .note-actions {
      display: flex;
      flex-direction: column;
      align-items: center; // centered horizontally in column
      padding-top: 5px;
      width: 100px;
      justify-content: flex-start; // anchored at the top of the column
    }
  }
}

.field {
  white-space: pre-wrap;
}
