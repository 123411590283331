@import '../../../styles/variables';

.hmp-clickable-row {
  &:hover {
    cursor: pointer;
  }
}

.resources-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.resources-action-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  .resource-action {
    margin-right: 10px;
  }
}