@import '../../styles/variables';

.textarea-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.textarea {
  padding: 4px 11px;
  border-radius: 2px;
  border: solid #d9d9d9 1px;
  outline: none;
  font-size: 14px;
  line-height: normal;
  width: 100%;
  background: none;
  resize: y;
  overflow-y: scroll;
  min-height: 50px;
  max-height: 200px;
}

.textarea.error {
  border: solid red 1px;
}

.textarea-after {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}

.textarea-after.error {
  color: red;
}

.textarea::-webkit-input-placeholder {
  color: #bfbfbf;
}

.textarea:-moz-placeholder { /* Firefox 18- */
  color: #bfbfbf;  
}

.textarea::-moz-placeholder {  /* Firefox 19+ */
  color: #bfbfbf;  
}

.textarea:-ms-input-placeholder {
  color: #bfbfbf;  
}

.textarea::placeholder {
  color: #bfbfbf;  
}