@import '../../../styles/variables';

.goal-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.goal-action-container {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: start;
  align-items: center;
  .goal-action {
    margin-right: 10px;
  }
}
.goal-table-row {
  cursor: pointer;
}