@import '../../../styles/variables';

.hmp-resource-editor {

  .ant-page-header {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 10px;
    .ant-page-header-heading {
      .ant-page-header-back-button {
        color: $primary-color;
      }
      .ant-page-header-heading-title {
        color: white;
      }
    }
  }

  .hmp-resource-editor-title,
  .hmp-resource-editor-summary,
  .hmp-resource-editor-topic,
  .hmp-resource-editor-cover,
  .hmp-resource-editor-body {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    max-width: 600px;

    label {
      color: black;
      font-size: 18px;
    }

    .counter {
      font-size: 12px;
      text-align: right;
    }

    input {
      color: black;
    }

    textarea {
      color: black;
    }

    .error {
      border: 2px solid red;
    }
  }

  .hmp-resource-editor-body {
    max-width: 100%;
  }

  .hmp-resource-editor-publish-date {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    max-width: 250px;

    label {
      color: black;
      align-items: center;

      span {
        margin-right: 8px;
        font-size: 18px;
      }
    }
  }
}

.hmp-resource-editor-topic-selector-popup-menu {
  width: 600px;

  .ant-cascader-menu {

    .ant-cascader-menu-item {
      width: 200px;

      .anticon-right {
        color: $submenu-background-color;
      }

      &.ant-cascader-menu-item-active {
        color: #449AFF;
      }
    }

    &:not(:first-of-type) {
      width: 400px;
    }
  }
}

.hmp-resource-editor-footer {
  z-index: 1000;
  position: sticky;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  bottom: 0px;
  margin-bottom: 30px;
  padding: 10px;
  background-color: #fff;

  .footer-action {
    margin-right: 8px;
  }
}