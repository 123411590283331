.thread-search-input {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  .ant-input-wrapper {
    background: #f4f4f8;
  }
  .ant-input {
    background: #f4f4f8;
    ::placeholder {
      text-align: center;
    }
    :focus::placeholder {
      text-align: left;
    }
  }
  .ant-input-search-icon {
    color: #8c8c8c
  }
}