@import '../../styles/variables';

.form-item {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

.form-item-icon {
  min-width: 30px;
  max-width: 50px;
  color: $font-color-primary;
}

.parent-form-item {
  margin-bottom: 0;
}

.admin-bold {
  color: #52c41a !important;
}