@import '../../styles/variables';

#logo-container {
  text-align: center;
  height: 64px;
  width: 100%;
  padding-top: 2px;

  #logo {
    max-height: 62px;
    max-width: 196px;
  }
}

#logo-container-collapsed {
  text-align: center;
  width: 80px;
  height: 64px;

  #logo {
    max-height: 62px;
    max-width: 78px;
  }
}

.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid $menu-item-hover-color !important;
}

#sidebar-menu {
  background-color: $menu-background-color;

  .sidebar-menu-item {
    a {
      color: $menu-item-color;
      &:hover {
        color: $menu-item-hover-color;
      }
    }
    &.ant-menu-item-selected {
      background-color: $menu-selected-item-background-color;
      a {
        color: $menu-selected-item-color;
      }
    }
  }

  &.ant-menu-inline, &.ant-menu-vertical, &.ant-menu-vertical-left {
    border-right: 0 solid red !important;
  }
}
