@import '../../../styles/variables';

.progress-stat-card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 5px;
  width: 100%;
}

.empty-container {
  width: 100%;
  background: white;
  border-radius: 2px;
}