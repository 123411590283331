.ant-collapse-item.ant-collapse-item-active.ant-collapse-no-arrow.collapse-header {
  .ant-collapse-header {
    padding: 0;
  }
  .ant-collapse-content {
    .ant-collapse-content-box {
      padding: 0;
    }
  }
}

.ant-collapse {
  .ant-collapse-item.ant-collapse-no-arrow.collapse-header  {
    .ant-collapse-header {
      padding: 0;
    }
  }
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
  .action {
    margin-left: 10px;
  }
}

.form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.user-creation-form {
  width: 50vw;
}

.react-tel-input {
  .selected-flag {
    padding-top: 10px;
  }
}
