@import '../../styles/variables';

.hmp-search-panel {
  margin-bottom: 10px;
  .hmp-search-panel-input-wrapper {

  }

  .hmp-search-panel-filters-wrapper {
    margin-top: 10px;
  }
}