@import '../../../styles/variables';

.announcement-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.announcement-action-container {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: start;
  align-items: center;
  .announcement-action {
    margin-right: 10px;
  }
}