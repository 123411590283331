/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@-webkit-keyframes antCheckboxEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
@keyframes antCheckboxEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
.ant-transfer-customize-list .ant-transfer-list {
  flex: 1 1 50%;
  width: auto;
  height: auto;
  min-height: 200px;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small {
  border: 0;
  border-radius: 0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small .ant-table-selection-column {
  width: 40px;
  min-width: 40px;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
  background: #fafafa;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content .ant-table-row:last-child td {
  border-bottom: 1px solid #f0f0f0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small .ant-table-body {
  margin: 0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 16px 0 4px;
}
.ant-transfer-customize-list .ant-input[disabled] {
  background-color: transparent;
}
.ant-transfer {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: flex;
  align-items: stretch;
}
.ant-transfer-disabled .ant-transfer-list {
  background: #f5f5f5;
}
.ant-transfer-list {
  display: flex;
  flex-direction: column;
  width: 180px;
  height: 200px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.ant-transfer-list-with-pagination {
  width: 250px;
  height: auto;
}
.ant-transfer-list-search {
  padding-right: 24px;
  padding-left: 8px;
}
.ant-transfer-list-search-action {
  position: absolute;
  top: 12px;
  right: 12px;
  bottom: 12px;
  width: 28px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 32px;
  text-align: center;
}
.ant-transfer-list-search-action .anticon {
  color: rgba(0, 0, 0, 0.25);
  transition: all 0.3s;
}
.ant-transfer-list-search-action .anticon:hover {
  color: rgba(0, 0, 0, 0.45);
}
span.ant-transfer-list-search-action {
  pointer-events: none;
}
.ant-transfer-list-header {
  display: flex;
  flex: none;
  align-items: center;
  height: 40px;
  padding: 8px 12px 9px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}
.ant-transfer-list-header > *:not(:last-child) {
  margin-right: 4px;
}
.ant-transfer-list-header > * {
  flex: none;
}
.ant-transfer-list-header-title {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  text-overflow: ellipsis;
}
.ant-transfer-list-header-dropdown {
  font-size: 10px;
  transform: translateY(10%);
  cursor: pointer;
}
.ant-transfer-list-header-dropdown[disabled] {
  cursor: not-allowed;
}
.ant-transfer-list-body {
  display: flex;
  flex: auto;
  flex-direction: column;
  overflow: hidden;
  font-size: 14px;
}
.ant-transfer-list-body-search-wrapper {
  position: relative;
  flex: none;
  padding: 12px;
}
.ant-transfer-list-content {
  flex: auto;
  margin: 0;
  padding: 0;
  overflow: auto;
  list-style: none;
}
.ant-transfer-list-content-item {
  display: flex;
  align-items: center;
  min-height: 32px;
  padding: 6px 12px;
  line-height: 20px;
  transition: all 0.3s;
}
.ant-transfer-list-content-item > *:not(:last-child) {
  margin-right: 8px;
}
.ant-transfer-list-content-item > * {
  flex: none;
}
.ant-transfer-list-content-item-text {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-transfer-list-content-item-remove {
  color: #1890ff;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  color: #d9d9d9;
}
.ant-transfer-list-content-item-remove:focus,
.ant-transfer-list-content-item-remove:hover {
  color: #40a9ff;
}
.ant-transfer-list-content-item-remove:active {
  color: #096dd9;
}
.ant-transfer-list-content-item-remove::after {
  position: absolute;
  top: -6px;
  right: -50%;
  bottom: -6px;
  left: -50%;
  content: '';
}
.ant-transfer-list-content-item-remove:hover {
  color: #40a9ff;
}
.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled).ant-transfer-list-content-item-checked:hover {
  background-color: #dcf4ff;
}
.ant-transfer-list-content-show-remove .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background: transparent;
  cursor: default;
}
.ant-transfer-list-content-item-checked {
  background-color: #e6f7ff;
}
.ant-transfer-list-content-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-transfer-list-pagination {
  padding: 8px 0;
  text-align: right;
  border-top: 1px solid #f0f0f0;
}
.ant-transfer-list-body-not-found {
  flex: none;
  width: 100%;
  margin: auto 0;
  color: rgba(0, 0, 0, 0.25);
  text-align: center;
}
.ant-transfer-list-footer {
  border-top: 1px solid #f0f0f0;
}
.ant-transfer-operation {
  display: flex;
  flex: none;
  flex-direction: column;
  align-self: center;
  margin: 0 8px;
  vertical-align: middle;
}
.ant-transfer-operation .ant-btn {
  display: block;
}
.ant-transfer-operation .ant-btn:first-child {
  margin-bottom: 4px;
}
.ant-transfer-operation .ant-btn .anticon {
  font-size: 12px;
}
.ant-transfer .ant-empty-image {
  max-height: -2px;
}
.ant-transfer-rtl {
  direction: rtl;
}
.ant-transfer-rtl .ant-transfer-list-search {
  padding-right: 8px;
  padding-left: 24px;
}
.ant-transfer-rtl .ant-transfer-list-search-action {
  right: auto;
  left: 12px;
}
.ant-transfer-rtl .ant-transfer-list-header > *:not(:last-child) {
  margin-right: 0;
  margin-left: 4px;
}
.ant-transfer-rtl .ant-transfer-list-header {
  right: 0;
  left: auto;
}
.ant-transfer-rtl .ant-transfer-list-header-title {
  text-align: left;
}
.ant-transfer-rtl .ant-transfer-list-content-item > *:not(:last-child) {
  margin-right: 0;
  margin-left: 8px;
}
.ant-transfer-rtl .ant-transfer-list-pagination {
  text-align: left;
}
.ant-transfer-rtl .ant-transfer-list-footer {
  right: 0;
  left: auto;
}
