@import '../../../styles/variables';

#hmp-forum-page {
   height: 100%;


  .post-tag-bubble {
    margin-left: 10px;
    padding-left: 5px;
    padding-right: 5px;
    height: 14px;
    font-size: 12px;
    color: white;
    border-radius: 7px;
  }
  .post-tag-bubble.deleted {
    background-color: red;
  }
  .post-tag-bubble.hidden {
    background-color: gray;
  }

  .unpublished {
    background-color: #389e0d;
    color: white;
  }

  .hmp-forum-page-tab {
     height: 100%;

    .hmp-forum-page-tab-search {

    }

    .hmp-forum-page-tab-container {
      display: flex;
      flex-direction: column;

      .hmp-forum-page-tab-content {
        flex: 1 1 auto;
        display: flex;
        flex-direction: row;
        margin-top: 8px;

        .postList {
          flex: 0 1 50%;
          .centerList {
            display: flex;
            justify-content: center;
          }
        }

        #postListLeft {
          margin-right: 2px;
        }

        #selectedPostRight {
          overflow: auto;
          margin-left: 20px;
        }
      }
    }
  }



  #post-container {
    width: 99%;
    height: min-content;
    border: 4px solid white;
    border-radius: 4px;
    background-color: white;
  }

  .small-font {
     font-size: 10px;

     .ant-btn {
      height: min-content;
      align-items: flex-end;
      font-size: 10px;
      border: none;
      padding: 0px;
     }
  }

  .title-extra {
    position: relative;
    width: 200px;
    margin-left: 10px;
  }

  .title-container {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
  }

  #comment-publish-date {
    display: table-cell;
    align-items: start;
  }

  #post-header-container {
    width: 100%;
    height: min-content;

    #title {
      height: 22px;
      position: relative;
      top: 8px;
      left: 8px;
      font-weight: bold;
      font-size: 16px;
    }

    .title-truncate {
      width: auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .title-no-truncate {
      width: calc(100% - 20px);
      height: 100%;
      margin-bottom: 30px;
    }

    #arm {
      position: absolute;
      top: 3px;
      right: 8px;
    }

    #after-title-container {
      position: relative;
      height: 60px;

        #avatar {
          position: absolute;
          top: 0px;
          left: 10px;
          z-index: 1;
        }

        #horizontal-rule {
          border-top: 1px solid #8185B3;
          opacity: 0.2;
          position: relative;
          top: 15px;
          width: calc(100% - 30px);
          padding-left: 15px;
          padding-right: 15px;
        }


        #username {
          position: absolute;
          top: 33px;
          left: 5px;
        }

        #publish-date {
          position: absolute;
          top: 0px;
          right: 15px;
        }

        #category {
          position: absolute;
          top: 16px;
          right: 15px;
        }

        #social-container {
          position: absolute;
          bottom: 3px;
          right: 0px;
        }

      .red-flag {
        color: red;
        &:hover {
          color: rgb(255, 131, 131);
        }
      }
    }
  }

  #post-body-container {
    margin-top: 5px;
    padding: 2px;
    min-height: 100px;
    border-radius: 4px;

    .partial-container {
      height: 90px;
    }

    .partial-player {
      height: 100%;
      width: 100%;
    }

    .full-container {
      height: 250px;
      width: 50%;
    }

    .full-player {
      width: 100%;
      height: 100%;
    }

    #body-text {
      position: relative;
      left: 5px;
      width: 50%;
      overflow: hidden;
    }

    .poll-body-options {
      max-height: 20px;
    }

    #body-media {
      display: flex;
      flex-direction: row;

      .alert-box {
        border: 5px solid red;
        border-radius: 4px;
      }
    }

    .body-media-partial {
      height: 95px;
    }

    .three-line-fade {
      position: relative;
      height: 4.3em; /* exactly three lines */
    }
    .three-line-fade:after {
      content: "";
      text-align: right;
      position: absolute;
      bottom: 0;
      right: 0px;
      width: 20%;
      height: 1.4em;
      background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
    }

     .one-line-fade {
          position: relative;
          height: 1.4em; /* exactly one line */
     }
     .one-line-fade:after {
       content: "";
       text-align: right;
       position: absolute;
       bottom: 0;
       right: 0px;
       width: 20%;
       height: 1.4em;
       background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
     }
  }

 // this fixes a problem where numbers were acting like tooltips on the comments
  .ant-comment-actions > li > span {
    color: #8185B3
  }

  .ant-tabs {
    height: 100%
  }

  .ant-tabs-content {
    height: calc(100% - 60px);
  }
}

.forum-post-link {
  margin-left: 5px;
}


#flag-stats-popover {

  #button-row {
    width: 300px;
    display: flex;
    justify-content: space-around;
    margin-bottom: 15px;
  }

   #title-row {
     display: flex;
     padding-top: 10px;
     justify-content: center;
     border: 1px solid #8185B3;
     border-bottom: none;
     border-radius: 4px 4px 0px 0px;
   }

   #table-row {
     width: 300px;
     display: flex;
     justify-content: space-around;
     border: 1px solid #8185B3;
     border-top: none;
     border-radius: 0 0 4px 4px;

     #table {
       width: 250px;

       .bottom-row {
          border-top: 1pt solid #8185B3
       }

       .center {
          text-align: center;
       }
     }
   }

}

#create-comment-popover {

  width: 500px;
  height: 300px;

  #button-row {
    width: 450px;
    display: flex;
    justify-content: start;
    margin-bottom: 15px;
  }

  p {
    margin-top: 7px;
    margin-left: 5px;
    margin-right: 15px;
  }

  button {
    margin-left: 20px;
  }

  .ant-cascader-picker {
    width: 250px;

    span {
      top: 40%;
    }

    .ant-cascader-picker-arrow {
      top: 40%;
    }
  }


}

.filter {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-top: 10px;
  white-space: pre;
}

#date-change-modal-content {
  display: flex;
  justify-content: start;

  #date-picker-container {
    margin-left: 10px;
  }
}

.add-comment-content {
  width: 600px;
}