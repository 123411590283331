@import './styles/variables';

body {
  font-family: "Nunito Sans", "sans-serif";
}

#main {
  background-color: #181929;
}

#content {
  margin: 20px;
  background-color: #181929;

  .title {
    font-size: x-large;
    margin-bottom: 10px;
  }
}

.tr-even-color {
  background-color: #E5EBEA;
}

.tr-odd-color {
  background-color: #EDF1F1;
}

.ant-menu-submenu > .ant-menu {
  background-color: $submenu-background-color;
}

.ant-progress-text  {
  color: #8185B3;
}

#test-env-banner {
  background-color: yellow;
  text-align: center;
}