@import '../../../styles/variables';

.activity-list {
  width: 100%;
  min-height: 100vh;
  
  .activity-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.activity-action-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  .activity-action {
    margin-right: 10px;
  }
}

.table-filter-search {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  .hmp-search-input-container {
    width: 100%;
    margin-bottom: 10px;
  }
  .filter-items {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    .filter {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      margin-bottom: 10px;
      white-space: pre;
    }
  }
}